<template>
  <div class="no-permission" id="mw">
    <a-result status="403" title="" sub-title="抱歉，您暂无权限查看">
      <template #extra>
        <!-- <a-button type="primary">
          Back Home
        </a-button> -->
      </template>
    </a-result>
  </div>
</template>
<script>


export default {
  name: "no-permission",
  components: {},
  data: function () {
    return {
      y: 0
    };
  },
  created() {
    // this.y = window.innerHeight - 260
  },
  mounted() {
    // this.$nextTick(() => {
    //   let w = this.$el.querySelector("#mw");
    //   w.style.height = window.innerHeigh + "px";
    // })
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
@import "../../styles/common.less";

.no-permission{
  background-color: #fff;
}



</style>
