<template>
  <div>
    <no-permission v-if="isNoPermisson" />
    <!-- <div v-if="module1Loading">
      <chart-card v-for="(item, index) in [0, 1, 2, 3]" :key="index" :loading="true" title="" total=""></chart-card>
    </div> -->
    <div class="module-1" v-if="isGranted('Pages.WorkBench.First')">
      <div class="w">
        <div class="i-w customer" v-if="!module1Loading">
          <div>{{ module1.customerCount }}</div>
          <div>本月新增项目数量</div>
        </div>
        <chart-card
          v-if="module1Loading"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
      <div class="w">
        <div class="i-w register" v-if="!module1Loading">
          <div>{{ module1.personnelCount }}</div>
          <div>本月注册用户数</div>
        </div>
        <chart-card
          v-if="module1Loading"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
      <div class="w">
        <div class="i-w amount" v-if="!module1Loading">
          <div>{{ module1.billAmountSum }}</div>
          <div>本月开票总金额</div>
        </div>
        <chart-card
          v-if="module1Loading"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
      <div class="w">
        <div class="i-w service" v-if="!module1Loading">
          <div>{{ module1.payPersonnelCount }}</div>
          <div>本月服务总人数</div>
        </div>
        <chart-card
          v-if="module1Loading"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
    </div>

    <div class="module-2" v-if="isGranted('Pages.WorkBench.Two')">
      <div style="padding: 0px 10px;" class="title">我的待办</div>
      <div class="module-c" v-if="!module2Loading">
        <div class="w" v-for="(item, index) in module2List" :key="index">
          <div class="i-w" @click="goUrl(item.path)">
            <div class="t">
              <div class="tl">
                <a-icon
                  type="notification"
                  :style="{ color: '#fff', fontSize: '15px' }"
                  :theme="'filled'"
                />
              </div>
              <div class="tr">{{ item.desc }}（{{ item.count }}条）</div>
            </div>
            <div class="b">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="module-c" v-if="module2Loading">
        <div class="w" v-for="(item, index) in [0, 1, 2, 3, 4]" :key="index">
          <div class="">
            <chart-card :loading="true" title="" total=""></chart-card>
          </div>
        </div>
      </div>
    </div>

    <div class="module-3" v-if="isGranted('Pages.WorkBench.Third')">
      <div class="m3-l">
        <div class="t">
          <div class="tl">开票数据统计：</div>
          <div class="tr">
            <a-select
              style="width: 300px"
              @change="selectChange"
              show-search
              :filter-option="filterOption"
              placeholder="请选择"
              v-model="companyId"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in orgOptions"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div id="myColumn"></div>
        <chart-card
          v-if="module3Loading2"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
      <div class="m3-r">
        <div class="title">客户开票额占比（总累计）</div>
        <div id="myPie"></div>
        <chart-card
          v-if="module3Loading2"
          :loading="true"
          title=""
          total=""
        ></chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import { ChartCard } from "@/components";
import NoPermission from '@/components/noPermission/noPermission.vue';

export default {
  mixins: [AppComponentBase],
  name: "dashboard",
  components: {
    ChartCard,
    NoPermission,
  },
  data() {
    return {
      // loading: true,
      commonService: null,
      module1: {
        customerCount: 0,
        personnelCount: 0,
        billAmountSum: 0,
        payPersonnelCount: 0,
      },
      module1Loading: true,
      module2: {
        taskRecordCount: 0,
        projectPersonnelCount: 0,
        settlementCount: 0,
        waitOpenInvoiceCount: 0,
        workOrderWaitingCount: 0,

        giveOutCount: 0,
        appyManagerAuditingCount: 0,
        claimCount: 0,
        failedDistributionCount: 0,
      },
      module2List: [],
      module2Loading: true,
      module3: {
        pie: null,
        pieInit: false,
        column: null,
        columnInit: false,
      },
      module3Loading1: true,
      module3Loading2: true,
      companyId: undefined,
      orgOptions: [],
      isNoPermisson: false,
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    if (this.isGranted("Pages.WorkBench.First")) {
      this._getWorkBenchInfo();
    } else {
      this.module1Loading = false
    }
    if (this.isGranted("Pages.WorkBench.Two")) {
      this._getSecBlockFirst();
    } else {
      this.module2Loading = false
    }
    if (this.isGranted("Pages.WorkBench.Third")) {
      this._getInvoicingSummaryData();
      this._getAllSelectList();
      this._getSiginCompanyInvoiceData("");
    } else {
      this.module3Loading1 = false
    }
    if (!this.isGranted("Pages.WorkBench.First") && !this.isGranted("Pages.WorkBench.Two") && !this.isGranted("Pages.WorkBench.Third")) {
      this.isNoPermisson = true
    }
  },
  methods: {
    initColumn(data) {
      this.module3Loading1 = true;
      if (!this.module3.columnInit) {
        this.module3.column = new G2.Chart({
          container: "myColumn",
          forceFit: true,
          height: 400,
        });
        this.module3.column.source(data);
        this.module3.column
          .interval()
          .position("yearMonth*monthTotalMoney")
          .color("receiptTypeName")
          .adjust([
            {
              type: "dodge",
              marginRatio: 1 / 32,
            },
          ]);
        this.module3.columnInit = true;
        this.module3.column.render();
      } else {
        this.module3.column.source(data);
        this.module3.column.guide().clear();
        this.module3.column.repaint();
      }
      this.module3Loading1 = false;
    },
    initPie(data) {
      this.module3.pie = new G2.Chart({
        container: "myPie",
        forceFit: true,
        width: 400,
        height: 400,
      });
      this.module3.pie.source(data, {
        percent: {
          formatter: function formatter(val) {
            val = val * 100 + "%";
            return val;
          },
        },
      });
      this.module3.pie.coord("theta", {
        radius: 0.75,
      });
      this.module3.pie.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}，总金额：{count}</li>',
      });
      this.module3.pie
        .intervalStack()
        .position("percent")
        .color("item")
        .label("percent", {
          formatter: function formatter(val, item) {
            // return item.point.item + ": " + val;
            return val;
          },
        })
        .tooltip("item*percent*count", function (item, percent, count) {
          percent = percent * 100 + "%";
          return {
            name: item,
            value: percent,
            count: count,
          };
        })
        .style({
          lineWidth: 1,
          stroke: "#fff",
        });
      if (!this.module3.pieInit) {
        this.module3.pieInit = true;
        this.module3.pie.render();
      } else {
        this.module3.pie.repaint();
      }
      this.module3Loading2 = false;
    },
    async _getSiginCompanyInvoiceData(companyId) {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Receipt/GetSiginCompanyInvoiceData",
          params: { companyId: companyId },
        });
        let arr = [];
        res.map((item) => {
          arr.push({
            yearMonth: item.yearMonth,
            receiptTypeName: item.receiptTypeName,
            monthTotalMoney: item.monthTotalMoney,
          });
        });
        this.initColumn(arr);
      } catch (error) {
        console.log(error);
      }
    },
    async _getInvoicingSummaryData() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Receipt/GetInvoicingSummaryData",
          params: {},
        });
        // console.log(res);
        let arr = [];
        arr = res.map((val) => {
          return {
            item: val.clientName,
            percent: val.percentage,
            count: val.totalMoney,
          };
        });
        this.initPie(arr);
      } catch (error) {
        console.log(error);
      }
    },
    async _getWorkBenchInfo() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/WorkBench1/GetWorkBenchInfo",
          params: {},
        });
        this.module1 = { ...res };
        this.module1Loading = false;
      } catch (error) {
        console.log(error);
        this.module1Loading = false
      }
    },
    async _getSecBlockFirst() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/WorkTable/GetSecBlockFirst",
          params: {},
        });
        this.module2 = { ...res };
        this._getSecBlockSecond();
      } catch (error) {
        console.log(error);
        this.module2Loading = false
      }
    },
    async _getSecBlockSecond() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/WorkTable/GetSecBlockSecond",
          params: {},
        });
        this.module2 = { ...this.module2, ...res };
        // console.log(this.module2);
        let arr = [];
        for (let k in this.module2) {
          arr.push({
            ...this.returnModule2ItemName(k),
            count: this.module2[k],
          });
        }
        // console.log(arr)
        this.module2List = arr;
        this.module2Loading = false;
      } catch (error) {
        this.module2Loading = false
        console.log(error);
      }
    },
    returnModule2ItemName(type) {
      switch (type) {
        case "taskRecordCount":
          return {
            title: "任务管理",
            desc: "待审核数",
            path: "/app/linggong/taskReportNewpannel",
          };
        case "projectPersonnelCount":
          return {
            title: "项目人员",
            desc: "待审核数",
            path: "/app/linggong/projectuser?auditStatus=0",
          };
        case "settlementCount":
          return {
            title: "结算管理",
            desc: "待审核数",
            path: "/app/linggong/settlement?status=1",
          };
        case "waitOpenInvoiceCount":
          return {
            title: "账单概览",
            desc: "待开票数",
            path: "/app/finanical/bill?status=1",
          };
        case "workOrderWaitingCount":
          return {
            title: "工单管理",
            desc: "待接单数",
            path: "/app/linggong/workorder",
          };
        case "giveOutCount":
          return {
            title: "发放管理",
            desc: "待发放数",
            path: "/app/linggong/salary?status=0",
          };
        case "appyManagerAuditingCount":
          return {
            title: "报名管理",
            desc: "待审核数",
            path: "/app/linggong/apply?status=0",
          };
        case "claimCount":
          return {
            title: "账单概览",
            desc: "流水待认领数",
            path: "/app/finanical/bill?status=7",
          };
        case "failedDistributionCount":
          return {
            title: "发放管理",
            desc: "发放失败人数",
            path: "/app/linggong/salary",
          };
      }
    },
    async _getAllSelectList() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetAllSelectList",
          params: {},
        });
        this.orgOptions = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    selectChange(val) {
      console.log(val);
      this._getSiginCompanyInvoiceData(val);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    goUrl(path) {
      if (!path) return;
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/common.less";
.module-1 {
  min-width: 1000px;
  padding: 10px 10px;
  #flex;
  #flex-hw;
  .w {
    padding: 10px;
    width: 25%;
    .i-w {
      padding: 20px;
      #tac;
      color: @color-white;
      #border-radius(3px);
      div:nth-of-type(1) {
        padding: 10px 0;
        font-size: 30px;
      }
      div:nth-of-type(2) {
      }
      &:hover {
        #box-shadow(0, 0, 10px, @color-gray);
        transition: 0.2s linear;
        // transform: scale3d(1.02, 1.02, 1);
        transform: translate3d(0, -3px, 0);
        div:nth-of-type(1),
        div:nth-of-type(2) {
          #fw;
        }
      }
    }
    .customer {
      background-color: #715ca4;
    }
    .register {
      background-color: #2bade6;
    }
    .amount {
      background-color: #eab763;
    }
    .service {
      background-color: #30baad;
    }
  }
}

.module-2 {
  min-width: 1000px;
  padding: 0 10px 10px;
  .title {
    padding-left: 10px;
    color: @color-lightblack;
    #fw;
  }
  .module-c {
    #flex;
    #flex-hw;
    .w {
      padding: 10px;
      width: 20%;
      .i-w {
        padding: 10px 0 10px 20px;
        #border-radius(3px);
        border: 1px solid @color-border-gray;
        background-color: @color-white;
        .t {
          padding: 14px 0;
          #flex;
          #flex-vc;
          color: @color-blue;
          .tl {
            #flex;
            #flex-vc;
            #flex-hc;
            #tac;
            width: 28px;
            height: 28px;
            background-color: @color-blue;
            #border-radius(50%);
          }
          .tr {
            #flex1;
            margin-left: 10px;
            font-size: 14px;
            #fw;
          }
        }
        .b {
          #fw;
          color: @color-lightblack;
          font-size: 12px;
        }
        &:hover {
          #box-shadow(0, 0, 6px, @color-blue);
          transition: 0.2s linear;
          transform: translate3d(0, -3px, 0);
        }
      }
    }
  }
}

.module-3 {
  min-width: 1000px;
  padding: 10px 20px 20px;
  #flex;
  margin-bottom: 30px;
  .m3-l {
    padding: 10px;
    width: 66%;
    border: 1px solid @color-border-gray;
    #border-radius(3px);
    background-color: @color-white;
    .t {
      #flex;
      #flex-vc;
      margin-bottom: 10px;
      .tl {
        width: 100px;
        #tar;
        color: @color-lightblack;
        #fw;
      }
      .tr {
        #flex1;
      }
    }
    #myColumn {
      #tac;
    }
    &:hover {
      #box-shadow(0, 0, 6px, @color-gray);
    }
  }
  .m3-r {
    // width: 25%;
    margin-left: 10px;
    padding: 10px;
    border: 1px solid @color-border-gray;
    #border-radius(3px);
    #flex1;
    background-color: @color-white;
    .title {
      color: @color-lightblack;
      #fw;
    }
    #myPie {
      #tac;
    }
    &:hover {
      #box-shadow(0, 0, 6px, @color-gray);
    }
  }
}

.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}
</style>
