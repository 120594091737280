<template>
  <a-layout style="background: #fff">
    <a-layout style="background: #fff">
      <a-layout-sider style="background: #fff" v-if="false" width="300">
        <a-list size="small">
          <a-list-item>
            <a
              @click="
                () => {
                  this.showConfig = true;
                }
              "
              type="primary"
            >
              配置管理 Config_00.json</a
            ></a-list-item
          >
          <a-list-item :key="item.path" v-for="item in templateList">
            <a type="primary"> 模板管理 {{ item.templateName }}</a></a-list-item
          >

          <div slot="footer"></div>
        </a-list>
      </a-layout-sider>
      <a-layout style="padding: 0; background: '#fff'">
        <a-layout-content
          :style="{
            background: '#fff',
            minHeight: '280px',
            padding: '12px',
          }"
        >
          <a-button @click="handleSearch" type="primary"> 生成 </a-button>
          <a-card style="margin-top: 10px" title="基本信息">
            <a-form :form="form" class="ant-advanced-search-form">
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-item :label="`实体名称`">
                    <a-input
                      placeholder="请输入实体名称"
                      v-decorator="[
                        `entityClassName`,
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入实体名称!',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="`表名称`">
                    <a-input
                      placeholder="请输入表名称"
                      v-decorator="[
                        `tableName`,
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入表名称',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="`文件夹名称`">
                    <a-input
                      placeholder="请输入文件夹名称"
                      v-decorator="[
                        `folderName`,
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入文件夹名称',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="`表名注释`">
                    <a-input
                      placeholder="请输入表名注释"
                      v-decorator="[
                        `entityName`,
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入表名注释',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-card>

          <a-card title="字段">

            <a-row :gutter="16" style="margin-top: 10px">
              <a-col :span="3" style="float:right">
                <a-button @click="addCol" type="primary"> + </a-button>
              </a-col>
            </a-row>
            <a-row
              :gutter="16"
              :key="index"
              style="margin-bottom: 10px"
              v-for="(item, index) in formChildList"
            >
              <a-col :span="7">
                <a-input
                  placeholder="字段名"
                  v-model="item.name"
                >
                </a-input>
              </a-col>
              <a-col :span="7">
                <a-input
                  v-model="item.explain"
                  placeholder="注释"
                >
                </a-input>
              </a-col>
              <a-col :span="7">
                <a-select
                  v-model="item.dataType"
                  placeholder="请选择类型"
                  style="width: 100%"
                >
                  <a-select-option :value="1"> DateTime </a-select-option>
                  <a-select-option :value="2"> string </a-select-option>
                  <a-select-option :value="3"> int </a-select-option>
                  <a-select-option :value="4"> long </a-select-option>
                  <a-select-option :value="5"> Guid </a-select-option>
                  <a-select-option :value="6"> decimal </a-select-option>
                  <a-select-option :value="7"> double </a-select-option>
                  <a-select-option :value="8"> bool </a-select-option>
<!--                  <a-select-option :value="9"> text </a-select-option>-->
                </a-select>
              </a-col>
              <a-col :span="3" v-if="index > 0">
                <a-button
                  @click="removeCol(index)"
                  type="primary"
                >
                  -
                </a-button>
              </a-col>
            </a-row>

          </a-card>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-modal
      :visible="showConfig"
      @cancel="
        () => {
          this.showConfig = false;
        }
      "
      title="配置管理"
      width="80%"
    >
      <p>配置文件名称：{{ configTemp.configName }}</p>
      <p>配置文件路径：{{ configTemp.path }}</p>
      <a-row :gutter="16" style="margin-bottom: 10px">
        <a-col :span="6"> 配置文件名 </a-col>
        <a-col :span="10"> 文件基本路径 </a-col>
        <a-col :span="4"> 文件名称 </a-col>
        <a-col :span="4"> 操作 </a-col>
      </a-row>
      <a-row
        :gutter="16"
        :key="item"
        style="margin-bottom: 10px"
        v-for="item in Object.keys(configTemp.config)"
      >
        <a-col :span="6">
          <a-input :value="item" @change="keyChange" placeholder="KEY"> </a-input>
        </a-col>
        <a-col :span="10">
          <a-input
            :value="configTemp.config[item].split(';')[0]"
            @change="
              (value) => {
                valueChange(item, value, 0);
              }
            "

            placeholder="VALUE"
          ></a-input>
        </a-col>
        <a-col :span="4">
          <a-input
            :value="configTemp.config[item].split(';')[1]"
            @change="
              (value) => {
                valueChange(item, value, 1);
              }
            "
            placeholder="VALUE"
          ></a-input>
        </a-col>

        <a-col :span="4" v-if="item.indexOf('_') > -1">
          <a-button type="primary"><a-icon type="edit" /></a-button>
          <a-button type="error"> <a-icon type="delete" /> </a-button>
        </a-col>
        <a-col
          :span="4"
          style="color: red; line-height: 32px"
          v-if="item.indexOf('_') == -1"
        >
          请直接修改文件</a-col
        >
      </a-row>
    </a-modal>
  </a-layout>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ServiceProxy } from "../smart/services";
import { message } from "ant-design-vue";
export default {
  mixins: [AppComponentBase],
  data() {
    return {
      services: null,
      collapsed: false,
      showConfig: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      configTemp: {},
      templateList: [],
      formChildList: [
        { index: 0, name: "", explain: "" },
        { index: 1, name: "", explain: "" },
      ],
    };
  },
  async created() {
    this.services = new ServiceProxy(this.$apiUrl, this.$api);
    this.configTemp = await this.services.getConfig();
    this.templateList = await this.services.GetTemplateList();
  },
  methods: {
    async handleSearch(e) {
      e.preventDefault();
      await this.form.validateFields(async (error, values) => {
        if (error) {
          return;
        }
        console.log(values);
        console.log(this.formChildList);
        let attributeList = this.formChildList.filter((value, index) => {
          return value.name != "" && value.dataType;
        });
        if (attributeList.length == 0) {
          alert("字段不可为空！");
          return;
        }
        console.log(attributeList);
        let postData = {
          ...values,
          attributeList,
        };
        let response = await this.services.Generate(postData);
        if (true) {
          message.success("生成成功！");
        }
      });
    },
    addCol() {
      this.formChildList.push({
        index: this.formChildList.length,
        name: "",
        explain: "",
      });
    },
    removeCol(index) {
      console.log(index)
     let temp = []
       temp = this.formChildList
      this.formChildList = []
      temp =temp.filter((value) => {
        return value.index != index;
      });
      this.formChildList  =  temp
      console.log(this.formChildList)
    //  this.formChildList.splice(index,1)
    },
    onChangeValue(value, index, item) {
      console.log('-------------onChangeValue------------')
      this.formChildList[index][item] = value;
      console.log(this.formChildList);
    },
    keyChange(e) {
      console.log(e.target.value);
    },
    valueChange(item, e, index) {
      console.log('-----------valueChange--------------')
      console.log(item, e, index);
    },
  },
};
</script>

<style>
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
</style>
