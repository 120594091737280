import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
export class ServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 获取单条详情
   * @param body (optional)
   * @return Success
   */
  async getConfig(id: string | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/CodeSmart/GetConfig";
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.process(_response);
  }

  /**
   * 获取单条详情
   * @param body (optional)
   * @return Success
   */
  async GetTemplateList(id: string | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/CodeSmart/GetTemplateList";
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.process(_response);
  }

  /**
   * 添加或者修改
   * @param body (optional)
   * @return Success
   */
  async Generate(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/CodeSmart/Generate";
    url_ = url_.replace(/[?&]$/, "");
    console.log(url_);
    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };
    var res = await this.instance.request(options_);
    var fnc = await this.process(res);

    return fnc;
  }

  protected process(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
